/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

export const pushGtmStockAlert = (
  productCode,
  productName,
  productCategory,
) => {
  push({
    event: GTM.EVENT.STOCK_ALERT,
    ecommerce: {
      items: [
        {
          item_id: productCode,
          item_name: productName,
          item_category: productCategory,
        },
      ],
    },
  });
};
