/* eslint-disable import/prefer-default-export */
import GTM from '@/utils/Controllers/GTM/Constants';
import { push } from '@/utils/Controllers/GTM/Utils';

const CLASS_NAME_SELECTOR = 'js-gtm-internal-search-form';

export const pushGtmInternalSearch = (payload, numberOfResults) => {
  push({
    event: GTM.EVENT.INTERNAL_SEARCH,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.INTERNAL_SEARCH_RESULT,
    eventLabel: payload,
    resultAmount: numberOfResults,
  });
};

const handleFormSubmit = e => {
  const inputText = e.currentTarget.querySelector(`[name='text']`);
  pushGtmInternalSearch(inputText.value);
};

export const pushGtmInternalSearchProduct = (payload, numberOfResults) => {
  push({
    event: GTM.EVENT.INTERNAL_SEARCH,
    eventCategory: GTM.EVENT.CATEGORY.ENGAGEMENT,
    eventAction: GTM.EVENT.ACTION.INTERNAL_SEARCH_PRODUCT_CLICK,
    eventLabel: payload,
    resultAmount: numberOfResults,
  });
};

export const handleProductClick = e => {
  e.preventDefault();
  const productElement = e.currentTarget;
  const productUrl = productElement.href;
  const { searchTerm } = productElement.dataset;
  const numberOfResults = productElement.dataset.results;

  pushGtmInternalSearchProduct(searchTerm, numberOfResults);
  window.location = productUrl;
};

export default () => {
  const searchForm = document.querySelector(`.${CLASS_NAME_SELECTOR}`);
  if (!searchForm) return;

  searchForm.classList.remove(CLASS_NAME_SELECTOR);
  searchForm.addEventListener('submit', handleFormSubmit);
};
