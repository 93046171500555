import getCloseStripe from '@/api/get/closeStripe';
import { isTrue } from '@/utils/Common/Booleans';
import EVENTS from '@/utils/Constants/Events';
import LINK from '@/utils/Constants/Link';

const suggestedCountry = `/${DLG.config.suggestedCountry}`;
const STRIPE_SELECTOR = '.js-dln-country-stripe';
const HIDDEN_CLASS = 'hide';
const GO_TO_CTA_ID = 'js-country-stripe-go-to';
const SELECT_COUNTRY_CTA_ID = 'js-country-stripe-selection';
const CLOSE_CTA_ID = 'js-country-stripe-close';

class StripeController {
  static init() {
    if (!isTrue(DLG.config.showStripe)) return;
    StripeController.showStripe();
  }

  static handleClose() {
    const el = document.querySelector(STRIPE_SELECTOR);
    if (el && !el.classList.contains(HIDDEN_CLASS)) {
      getCloseStripe({
        successCB: () => {
          el.classList.add(HIDDEN_CLASS);
          DLG.EVE.emit(EVENTS.STRIPE.CLOSED);
        },
      });
    }
  }

  static showStripe() {
    const stripe = document.querySelector('.dln-country-stripe');
    stripe.classList.add('visible');
    StripeController.initBtnEvents();
  }

  static initBtnEvents() {
    const goToCta = document.getElementById(GO_TO_CTA_ID);
    const selectCountryCta = document.getElementById(SELECT_COUNTRY_CTA_ID);
    const closeCta = document.getElementById(CLOSE_CTA_ID);

    if (goToCta) {
      goToCta.addEventListener('click', () => {
        window.location.href = suggestedCountry;
      });
    }

    if (selectCountryCta) {
      selectCountryCta.addEventListener('click', () => {
        window.location.href = LINK.LANGUAGE_SELECTION();
      });
    }

    if (closeCta) {
      closeCta.addEventListener('click', () => {
        StripeController.handleClose();
      });
    }
  }
}

export default StripeController;
